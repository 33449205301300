<template>
  <v-app>
    <v-content>
        <section id="hero">
          <v-row no-gutters>
<!--            <video playsinline autoplay muted loop poster='@/assets/jlslogo.png' height="100%" width="100%">-->
<!--              <source :src='require("@/assets/jlsvideo.mp4")' type='video/mp4'>-->
<!--            </video>-->
            <v-img src="@/assets/jlslogo.png"></v-img>
          </v-row>
        </section>

      <section id="hero2"
               class="grey darken-4">
        <br>
        <br>
        <v-row no-gutters>
            <v-theme-provider >
              <v-container fill-height>
                <v-row
                        align="center"
                        class="white--text mx-auto"
                        justify="center"
                >
                  <v-col
                          class="white--text text-center"
                          cols="12"
                          tag="h1"
                  >
                    <span
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                            class="font-weight-light"
                    >
                      The Atlanta Java User Group and Devnexus conference is excited to host the annual
                    </span>


                    <br>
                    <br>
                    <span
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                            class="font-weight-black"
                    >
                      JUG LEADERS SUMMIT
                    </span>

                  </v-col>

                  <v-btn
                          class="align-self-end"
                          fab
                          outlined
                          @click="$vuetify.goTo('#about-me')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-theme-provider>
        </v-row>
      </section>


      <section
              id="features"
              class="grey lighten-3"
      >

        <v-container class="text-center">
<!--          <h2 class="display-2 font-weight-bold mb-3">info</h2>-->

<!--          <h3 class="display-1  mb-3">-->
<!--            The JUG Leaders Summit is hosted by AJUG at the Devnexus conference.</h3>-->

            <br>
          <h3 class="display-1  mb-3">
            We're excited to welcome JUG leaders for the 3rd edition on April 9, 2024.
          </h3>
          <br>
          <h4 class="display-1  mb-3">
            Registration is FREE, but you must be a verifiable JUG Leader.
          </h4>

          <h4 class="display-1  mb-3">
            The JUG Leaders Summit is during the workshop day of the <a href="https://devnexus.com">Devnexus conference</a>
            <br>
            <br>

            <v-btn class="v-btn--rounded v-btn"
            >
              <a class="" href="https://ajug.typeform.com/to/REd8HZnl">
                Registration and CFP form
              </a>
            </v-btn>


          </h4>
          <br>
          <v-row>
            <v-col
                    v-for="({ icon, title, text }, i) in features"
                    :key="i"
                    cols="12"
                    md="4"
            >
              <v-card
                      class="py-12 px-4"
                      color="grey lighten-5"
                      flat
                      height="350"
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                            color="primary"
                            size="88"
                    >
                      <v-icon
                              large
                              v-text="icon"
                      ></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                        class="justify-center font-weight-black text-uppercase"
                        v-text="title"
                ></v-card-title>

                <v-card-text
                        class="subtitle-1"
                        v-text="text"
                >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="stats">
        <v-parallax
                :height="$vuetify.breakpoint.smAndDown ? 800 : 600" width="100%"
                src="@/assets/jlslogo.png"
        >
          <v-container fill-height>
<!--            <v-row class="mx-auto">-->
<!--              <v-col-->
<!--                      v-for="[value, title] of stats"-->
<!--                      :key="title"-->
<!--                      cols="12"-->
<!--                      md="3"-->
<!--              >-->
<!--                <div class="text-center">-->
<!--                  <div-->
<!--                          class="display-3 font-weight-black mb-4"-->
<!--                          v-text="value"-->
<!--                  ></div>-->

<!--                  <div-->
<!--                          class="title font-weight-regular text-uppercase"-->
<!--                          v-text="title"-->
<!--                  ></div>-->
<!--                </div>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </v-container>
        </v-parallax>
      </section>

<!--      <section id="blog">-->
<!--        <div class="py-12"></div>-->

<!--        <v-container>-->
<!--          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Blog</h2>-->

<!--          <v-responsive-->
<!--                  class="mx-auto mb-12"-->
<!--                  width="56"-->
<!--          >-->
<!--            <v-divider class="mb-1"></v-divider>-->

<!--            <v-divider></v-divider>-->
<!--          </v-responsive>-->

<!--          <v-row>-->
<!--            <v-col-->
<!--                    v-for="({ src, text, title }, i) in articles"-->
<!--                    :key="i"-->
<!--                    cols="12"-->
<!--                    md="4"-->
<!--            >-->
<!--              <v-img-->
<!--                      :src="src"-->
<!--                      class="mb-4"-->
<!--                      height="275"-->
<!--                      max-width="100%"-->
<!--              ></v-img>-->

<!--              <h3-->
<!--                      class="font-weight-black mb-4 text-uppercase"-->
<!--                      v-text="title"-->
<!--              ></h3>-->

<!--              <div-->
<!--                      class="title font-weight-light mb-5"-->
<!--                      v-text="text"-->
<!--              ></div>-->

<!--              <v-btn-->
<!--                      class="ml-n4 font-weight-black"-->
<!--                      text-->
<!--              >-->
<!--                Continue Reading-->
<!--              </v-btn>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-container>-->

<!--        <div class="py-12"></div>-->
<!--      </section>-->

      <v-sheet
              id="contact"
              color="#333333"
              dark
              tag="section"
              tile
      >
        <div class="py-12"></div>

        <v-container>
<!--            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">CFP Info</h2>-->
<!--            <h2 class="  mb-3  text-center">-->
<!--We're looking for 30 minute sessions where you can present, lead a discussion, or host a panel - including Q&A.-->
<!--Please submit all ideas by February 1. We'll let you know by February 15 if we have selected your session.-->
<!--            </h2>-->
<!--            <v-btn :style="{left: '50%', transform:'translateX(-50%)'}"-->
<!--                    class="ml-n4 font-weight-black"-->


<!--            >-->
<!--                <a href="https://sessionize.com/devnexus-2023/?e=478c41">-->
<!--                Submit your presentation, panel, or discussion ideas!-->

<!--                </a>-->

<!--            </v-btn>-->
<!--          <br>-->
<!--          <h3 class="text-center">Be sure to pick the "JUG Leader Summit" track when you enter in your ideas!</h3>-->
<!--            <br>-->

<!--            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Register</h2>-->


<!--            <br>-->

          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Schedule</h2>
<h3 class="display-2 font-weight-bold mb-3 text-uppercase text-center">April 9, 10am - 3pm</h3>
<!--          <table class="waffle" border-spacing="40" cellpadding="0">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th class="row-header freezebar-origin-ltr"></th>-->

<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R0" style="height: 20px;" class="row-headers-background">-->
<!--              </th>-->
<!--              <td class="s0" dir="ltr">Time</td>-->
<!--              <td class="s0" dir="ltr">Session</td>-->
<!--              <td class="s0" dir="ltr">Speakers</td>-->
<!--              <td class="s0" dir="ltr">Speakers</td>-->
<!--              <td class="s0" dir="ltr">Speakers</td>-->
<!--              <td class="s0" dir="ltr">Speakers</td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R1" style="height: 20px;" class="row-headers-background">-->
<!--              </th>-->
<!--              <td class="s1" dir="ltr">10:00 AM</td>-->
<!--              <td class="s0" dir="ltr">Welcome to JLS 2023</td>-->
<!--              <td class="s0" dir="ltr">Pratik Patel</td>-->
<!--              <td class="s0" dir="ltr">Burk Hufnagel</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R2" style="height: 20px;" class="row-headers-background">-->
<!--              </th>-->
<!--              <td class="s1" dir="ltr">10:30 AM</td>-->
<!--              <td class="s0" dir="ltr">Java in Education</td>-->
<!--              <td class="s0" dir="ltr">Heather VanCura</td>-->
<!--              <td class="s0" dir="ltr">Frank Greco</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R3" style="height: 20px;" class="row-headers-background">-->
<!--              </th>-->
<!--              <td class="s1" dir="ltr">11:00 AM</td>-->
<!--              <td class="s0" dir="ltr">Everything a JUG Leader Needs to Known about Foojay.io</td>-->
<!--              <td class="s0" dir="ltr">Geertjan Wielenga</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R4" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">11:30 AM</td>-->
<!--              <td class="s0" dir="ltr">Machine Learning for JUG Leaders</td>-->
<!--              <td class="s0" dir="ltr">Frank Greco</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R5" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">12:00 PM</td>-->
<!--              <td class="s0" dir="ltr">Lunch</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R6" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">1:00 PM</td>-->
<!--              <td class="s0" dir="ltr">Java User Groups back to the real world BOF</td>-->
<!--              <td class="s0" dir="ltr">Richard Fichtner</td>-->
<!--              <td class="s0" dir="ltr">Rodrigo Graciano</td>-->
<!--              <td class="s0" dir="ltr">Frank Greco</td>-->
<!--              <td class="s0" dir="ltr">Jorge Cajas</td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R7" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">2:00 PM</td>-->
<!--              <td class="s0" dir="ltr">Break</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R8" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">2:30 PM</td>-->
<!--              <td class="s0" dir="ltr">Growing new speakers</td>-->
<!--              <td class="s0" dir="ltr">Burk Hufnagel</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R9" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">3:00 PM</td>-->
<!--              <td class="s0" dir="ltr">How to start the new JUG for whole country and why you have to do it?</td>-->
<!--              <td class="s0" dir="ltr">Andrei Kogun</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R10" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">3:30 PM</td>-->
<!--              <td class="s0" dir="ltr">Open time for discussions</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R11" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">5:00 PM</td>-->
<!--              <td class="s0" dir="ltr">Jakarta Meetup @ Der Biergarten</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            <tr style="height: 20px">-->
<!--              <th id="1127999925R12" style="height: 20px;" class="row-headers-background">-->

<!--              </th>-->
<!--              <td class="s1" dir="ltr">7:00 PM</td>-->
<!--              <td class="s0" dir="ltr">JUG Leader / Speaker Dinner</td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
            <br>
            <br>
            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contact</h2>

          <v-responsive
                  class="mx-auto mb-12"
                  width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
            <h2 class="display-2  mb-3  text-center">info@ajug.org</h2>

          </v-theme-provider>
        </v-container>

        <div class="py-12"></div>
      </v-sheet>
    </v-content>

    <v-footer
            class="justify-center"
            color="#292929"
            height="100"
    >
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; {{ (new Date()).getFullYear() }} — ajug.org - devnexus.com
      </div>
    </v-footer>
  </v-app>
</template>

<script>
// import VideoParallax from 'vuetify-video-parallax';
let ROOT_PATH = 'https://jugleaderssummit.com';

export default {
  name: 'JugLeadersSummit',
  components: {
    // VideoParallax
  },
  data () {
    return {
      articles: [
        {
          src: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
          title: 'Mobile first & Responsive',
          text: 'Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.',
        },
        {
          src: 'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
          title: 'Think outside the box',
          text: 'Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.',
        },
        {
          src: 'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80',
          title: 'Small changes, big difference',
          text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
        },
      ],
      features: [
          {
              icon: 'mdi-account-group',
              title: 'Java User Groups',
              text: 'With hundreds of Java User Groups around the world, we can come together and share experiences and tips for building better communities.',
          },
        {
          icon: 'mdi-file-outline',
          title: 'Call for Papers',
          text: 'We\'re looking for you to do a short presentation and lead a discussion. Topics shouls be on the issues we all face when running a JUG.',
        },
        {
          icon: 'mdi-account-plus',
          title: 'Registration',
          text: 'Registration open until February 15. If you are unable to have your company pay for a ticket to the Devnexus conference, please indicate this in the registration form',
        },
      ],
      stats: [
        ['24k', 'Github Stars'],
        ['330+', 'Releases'],
        ['1m', 'Downloads/mo'],
        ['5m', 'Total Downloads'],
      ],
        logo: ROOT_PATH + require('./assets/jlslogo.png')
    }
  },
    metaInfo() {
        return {
            meta: [
                // Twitter Card
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:title', content: 'JUG LEADERS SUMMIT'},
                {name: 'twitter:description', content: 'JUG LEADERS SUMMIT 2024.'},
                // image must be an absolute path
                {name: 'twitter:image', content: this.logo},
                // Facebook OpenGraph
                {property: 'og:title', content: 'JUG LEADERS SUMMIT'},
                {property: 'og:site_name', content: 'JUG LEADERS SUMMIT 2024'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content:  this.logo},
                {property: 'og:description', content: 'JUG LEADERS SUMMIT 2024'}
            ]
        }
    }
};
</script>

<style>
.s1 {
  width: 100px;
}
.ritz .waffle .s1 {
  background-color: #ffffff;
  text-align: right;
  color: #000000;
  font-family: 'Arial';
  font-size: 10pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}

.ritz .waffle .s0 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'Arial';
  font-size: 10pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}

.link-text {
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
  font-size: x-large;
  text-align: center;
}
</style>
